exports.components = {
  "component---src-pages-aktuality-js": () => import("./../../../src/pages/aktuality.js" /* webpackChunkName: "component---src-pages-aktuality-js" */),
  "component---src-pages-edicna-cinnost-js": () => import("./../../../src/pages/edicna-cinnost.js" /* webpackChunkName: "component---src-pages-edicna-cinnost-js" */),
  "component---src-pages-fotogaleria-js": () => import("./../../../src/pages/fotogaleria.js" /* webpackChunkName: "component---src-pages-fotogaleria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nove-knihy-js": () => import("./../../../src/pages/nove-knihy.js" /* webpackChunkName: "component---src-pages-nove-knihy-js" */),
  "component---src-pages-o-kniznici-js": () => import("./../../../src/pages/o-kniznici.js" /* webpackChunkName: "component---src-pages-o-kniznici-js" */),
  "component---src-pages-podcasty-js": () => import("./../../../src/pages/podcasty.js" /* webpackChunkName: "component---src-pages-podcasty-js" */),
  "component---src-pages-pre-kniznice-js": () => import("./../../../src/pages/pre-kniznice.js" /* webpackChunkName: "component---src-pages-pre-kniznice-js" */),
  "component---src-pages-sluzby-js": () => import("./../../../src/pages/sluzby.js" /* webpackChunkName: "component---src-pages-sluzby-js" */),
  "component---src-pages-verejne-informacie-js": () => import("./../../../src/pages/verejne-informacie.js" /* webpackChunkName: "component---src-pages-verejne-informacie-js" */)
}

